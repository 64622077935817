import React from 'react';
import { Redirect } from 'react-router-dom';
import { I18NContext } from '../../I18NContext';
import {
    CancellationReasonFilterOption,
    OrderSummaryPageLabels,
    CancelBottomSheetDetails
} from '../component';
import CancelFormSelectV2 from './CancelFormSelectV2';
import cancelOrder from '../../accessors/CancelOrderAccessor';
import { CancellationDropdown, CancellationDropdownItem } from './CancellationDropdown';

import appMetrics from '../../common/MetricsService';
import MetricsConstants from '../../common/MetricsConstants';
import * as KatalMetrics from '@amzn/katal-metrics';
import OrderHistoryRedirectionComponentRow from '../order/OrderHistoryRedirectionComponent'
import {hasAnnotation} from "./PaymentUtils";

interface CancelFormV2Props {
    cancelDetails: CancelBottomSheetDetails;
    orderSummaryPageLabels: OrderSummaryPageLabels;
    refresh?: () => void;
    setCancelReason: (cancelFilterValue: string) => void;
    toggleDropdown: (isOpen: boolean) => void;
    cancelFilterOption?: CancellationReasonFilterOption;
}

interface CancelFormV2State {
    selectorRef: React.RefObject<any>;
    disableButton: boolean;
    alert: any;
}

class CancelFormV2 extends React.Component<CancelFormV2Props, CancelFormV2State> {

    constructor(props: CancelFormV2Props) {
        super(props);

        this.state = {
            selectorRef: React.createRef(),
            alert: false,
            disableButton: false
        }
    }

    render() {
        const cancelReason = this.props.orderSummaryPageLabels.cancelForm.cancelReason;
        const showCancelDrop = cancelReason && cancelReason.cancelReasonFilterOptions;
        const button = this.state.disableButton || (showCancelDrop && !this.props.cancelFilterOption) ?
            <pui-button spacingTop='small' disabled onClick={() => this._onSubmit()} label={this.props.orderSummaryPageLabels.cancelForm.cancelButton} /> :
            <pui-button id="button-to-cancel" data-csa-c-type="button" data-csa-c-action="cancel" data-csa-c-content-id="button-to-cancel" data-csa-c-slot-id="main"
                        spacingTop='small' onClick={() => this._onSubmit()} label={this.props.orderSummaryPageLabels.cancelForm.cancelButton} />

        return (<pui-stack direction='vertical' mainAxisArrangement='start' secondaryAxisArrangement='start'
            flex="true">
            {this.state.alert}
            <pui-heading input={this.props.orderSummaryPageLabels.cancelForm.cancelTitle} textSize='extra-large' textAlign='left'/>
                {this.isAutoRefill() &&
                    (<pui-stack mainaxisarrangement="start" secondaryaxisarrangement="start" spacingTop="medium"
                                direction='horizontal'>
                <pui-section spacingTop="mini" spacingRight="small" style={{'width':'5%', 'max-width':'5%'}}>
                <pui-icon imgClass="status-warning-icon"></pui-icon>
                </pui-section>
                <pui-stack pui-stack direction='vertical'>
                <pui-text input={this.props.orderSummaryPageLabels.cancelForm.cancelFormTitle} fontWeight="bold"></pui-text>
                <pui-text input={this.props.orderSummaryPageLabels.cancelForm.cancelFormMessage}  textAlign='left'/>
                </pui-stack>
             </pui-stack>)
             }
                {this._createCancellationCheckbox()}

                {showCancelDrop && (
                    <pui-section style={{ width: '100%' }}>
                        <CancellationDropdown
                        label={`${cancelReason.cancelReasonLabel}`}
                        placeHolderLabel={`${cancelReason.cancelReasonSelectLabel}`}
                        onSelectionChange={this.props.setCancelReason}
                        selectedOption={this.props.cancelFilterOption}
                        onToggle={this.props.toggleDropdown}
                        >
                        {cancelReason.cancelReasonFilterOptions.map((filterOption) => (
                            <CancellationDropdownItem key={filterOption.value} filterOption={filterOption} />
                        ))}
                        </CancellationDropdown>
                    </pui-section>
                    )}
            {button}
        </pui-stack>
        )
    }

    _createCancellationCheckbox() {
        return (<pui-selector spacingBottom="medium" spacingTop="medium" multiselect ref={this.state.selectorRef} style={{ width: '100%' }}>
            <CancelFormSelectV2 selectButton={(disable: boolean) => this._selectItem(disable)}
                                cancelFormSelectItem={this.createCancelFormSelectItem()}
                                isAutoRefill={this.isAutoRefill()}/>
                </pui-selector>)
    }

    createCancelFormSelectItem = () => {
        return {
            headline: this.props.cancelDetails.cancellationGroup.headline,
            subline: this.props.cancelDetails.cancellationGroup.subline,
            autoRefillLine: this.props.cancelDetails.cancellationGroup.autoRefillLine || ""
        }
    }

    _selectItem(disableButton: boolean) {
        this.setState({ disableButton });
    }

    _onSubmit() {
        const cancelReason = this.props.cancelFilterOption ? `${this.props.cancelFilterOption?.value}` : null;
        const actionMetricsPublisher = appMetrics.getMetricsPublisherForMethod("CancelOrder");
        const attemptMetric = new KatalMetrics.Metric.TimedAttempt(MetricsConstants.METRIC_CANCEL_ORDER_REQUEST).withMonitor();

        this.setState({
            alert: false,
            disableButton: true,
        });

        const orderIds = this.props.cancelDetails.orderIds;
        const purchaseId = this.props.cancelDetails.purchaseId;
        cancelOrder(purchaseId, orderIds, cancelReason, this.props.cancelDetails.csrfToken)
            .then((response: any) => {
                if ("CANCEL_PENDING" === response.cancellationStatus) {
                    this.setState({
                        alert: <pui-box spacingBottom="small" style={{ width: '85%' }} theme="solid">
                            <pui-text input={this.props.orderSummaryPageLabels.cancelForm.cancelPending} />
                        </pui-box>
                    });
                    if (this.isAutoRefill()) {
                        this.setState({
                            alert: <Redirect to={{
                                    pathname: '/',
                                    state: { orderHistoryRedirectionComponentRow: <OrderHistoryRedirectionComponentRow isRedirected={true} redirectionMessage={this.props.orderSummaryPageLabels.redirectCancellationMessages.cancellationPendingAutoRefillRedirectMessage} redirectionTitle={this.props.orderSummaryPageLabels.redirectCancellationMessages.cancellationPendingAutoRefillRedirectTitle}></OrderHistoryRedirectionComponentRow> }
                                    }}/>
                        })
                    }
                    if (this.props.refresh) {
                        this.props.refresh();
                    }
                } else {
                    if (this.isAutoRefill()) {
                        this.setState({
                            alert: <Redirect to={{
                                    pathname: '/',
                                    state: { orderHistoryRedirectionComponentRow: <OrderHistoryRedirectionComponentRow isRedirected={true} redirectionMessage={this.props.orderSummaryPageLabels.redirectCancellationMessages.cancellationSuccessAutoRefillRedirectMessage} redirectionTitle={this.props.orderSummaryPageLabels.redirectCancellationMessages.cancellationSuccessRedirectTitle}></OrderHistoryRedirectionComponentRow>}
                                    }}/>
                        })
                    }
                    else {
                        this.setState({
                            alert: <pui-box spacingBottom="small" style={{ width: '85%' }} theme="success">
                                <pui-text input={this.props.orderSummaryPageLabels.cancelForm.cancelSuccess} />
                            </pui-box>
                        })
                    }
                }
                attemptMetric.setSuccess();
                actionMetricsPublisher.publish(attemptMetric);
            })
            .catch(() => {
                this.setState({
                    alert: <pui-box style={{ width: '85%' }} theme="alert">
                        <pui-text input={this.props.orderSummaryPageLabels.cancelForm.cancelFailed} />
                    </pui-box>
                });
                attemptMetric.setFailure();
                actionMetricsPublisher.publish(attemptMetric);
            });
        
    }

    isAutoRefill() {
        return hasAnnotation(this.props.cancelDetails.cancellationGroup.annotations, 'AutoRefill')
    }
}

CancelFormV2.contextType = I18NContext;

export default CancelFormV2;
