import React, { useEffect, useRef, useState } from "react";
import { CancellationReasonFilterOption } from '../component';

interface ICancellationDropdownProps {
  selectedOption?: CancellationReasonFilterOption;
  onSelectionChange?: (optionValue: string) => void;
  label?: string;
  placeHolderLabel?: string;
  onToggle?: (isOpen: boolean) => void;
}

export const CancellationDropdown: React.FC<ICancellationDropdownProps> = ({
  children,
  selectedOption,
  onSelectionChange,
  label,
  placeHolderLabel,
  onToggle
}) => {
  const mobileDropdownRef = useRef<HTMLElement>();
  const desktopDropdownRef = useRef<HTMLElement>();
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const mobileDropdownEl: any = mobileDropdownRef.current;
    const desktopDropdownEl: any = desktopDropdownRef.current;
    
    if (!mobileDropdownEl || !desktopDropdownEl) return;

    const setupDropdown = (dropdownEl: any) => {
      if (!!selectedOption) {
        dropdownEl.value = selectedOption.value;
        dropdownEl.selectedText = selectedOption.key;
      }

      const originalOnSelect = dropdownEl.onSelect;
      dropdownEl.onSelect = (newValue: any) => {
        if (originalOnSelect) {
          originalOnSelect.call(dropdownEl, newValue);
        }
        onSelectionChange?.(newValue);
        setIsOpen(false);
        onToggle?.(false);
      };

      const handleDropdownClick = () => {
        const newIsOpen = !isOpen;
        setIsOpen(true);
        onToggle?.(true);
      };

      dropdownEl.addEventListener('click', handleDropdownClick);

      return () => {
        dropdownEl.removeEventListener('click', handleDropdownClick);
        dropdownEl.onSelect = originalOnSelect;
      };
    };

    const cleanupMobile = setupDropdown(mobileDropdownEl);
    const cleanupDesktop = setupDropdown(desktopDropdownEl);

    return () => {
      cleanupMobile();
      cleanupDesktop();
    };
  }, [mobileDropdownRef, desktopDropdownRef, selectedOption, onSelectionChange, onToggle, isOpen]);

  return (
    <>
      <pui-dropdown-two
        class="mobile-only"
        style={{
          height: isOpen ? '150px' : 'auto',
          overflow: 'hidden',
          transition: 'height 0.3s ease-in-out'
        }}
        ref={mobileDropdownRef}
        label={label}
        placeholder={placeHolderLabel}
        enableScrollbar
      >
        {children}
      </pui-dropdown-two>

      <pui-dropdown-two
        class="tablet-and-desktop-only"
        style={{
          height: 'auto',
          overflow: 'hidden'
        }}
        ref={desktopDropdownRef}
        label={label}
        placeholder={placeHolderLabel}
      >
        {children}
      </pui-dropdown-two>
    </>
  );
};

interface ICancellationDropdownItemProps {
  key: string,
  filterOption: CancellationReasonFilterOption;
}

export const CancellationDropdownItem: React.FC<ICancellationDropdownItemProps> = ({
  key,
  filterOption
}) => {
  return <pui-dropdown-item-two id={key} type="option" label={filterOption.key} value={filterOption.value} />;
};
