import React from 'react';
import { I18NContext } from '../../I18NContext';
import CancelFormV2 from './CancelFormV2';
import { CancelBottomSheetDetails, OrderSummaryPageLabels, CancellationReasonFilterOption } from '../component';
import "./Common.css";
import ErrorBoundary from '../../ErrorBoundary';

interface CancelV2Props {
    orderSummaryPageLabels: OrderSummaryPageLabels;
    cancelDetails: CancelBottomSheetDetails;
    refresh?: () => void;
}

interface CancelV2State {
    button: React.RefObject<any>;
    bottomSheet: React.RefObject<any>;
    showCloseButton: boolean;
    isDropdownOpen: boolean;
    cancelFilterOption?: CancellationReasonFilterOption;
}

class CancelV2 extends React.Component<CancelV2Props, CancelV2State> {
    static contextType = I18NContext;

    constructor(props: CancelV2Props) {
        super(props);
        this.state = {
            button: React.createRef(),
            bottomSheet: React.createRef(),
            showCloseButton: true,
            isDropdownOpen: false,
            cancelFilterOption: undefined
        }
    }

    _openBottomSheet = (e: React.MouseEvent) => {
        e.preventDefault();
        if (this.state.bottomSheet.current) {
            this.state.bottomSheet.current.show();
            this.setState({ showCloseButton: true });
        }
    }

    setCancelReason = (cancelFilterValue: string) => {
        let newOption;
        this.props.orderSummaryPageLabels.cancelForm.cancelReason.cancelReasonFilterOptions.forEach(function(cancellationOption) {
            if(cancelFilterValue === cancellationOption.value) {
                newOption = cancellationOption;
            }
        })
        this.setState({
            cancelFilterOption: newOption,
            showCloseButton: true,
            isDropdownOpen: false
        }, () => {
            this.updateCloseButtonVisibility();
        });
    };

    toggleDropdown = (isOpen: boolean) => {
        this.setState({ 
            isDropdownOpen: isOpen,
            showCloseButton: !isOpen
        }, () => {
            this.updateCloseButtonVisibility();
        });
    }

    render() {
        return (
            <>
                <pui-stack
                    direction="vertical"
                    ref={this.state.button}
                    onClick={this._openBottomSheet}
                    class="clickable"
                >
                    <pui-stack-item-button buttonPadding="none">
                        <pui-text
                            input={this.props.orderSummaryPageLabels.cancelForm.cancelItemInThisOrder}
                            textSize="medium"
                            color="black"
                            paddingTop="medium"
                            paddingBottom="medium"
                        />
                    </pui-stack-item-button>
                </pui-stack>
                <ErrorBoundary>
                    <pui-bottom-sheet
                        id="cancellation-modal"
                        data-csa-c-content-id="cancellation-modal"
                        data-csa-c-type="popup"
                        data-csa-c-slot-id="order-detail"
                        hideLink
                        ref={this.state.bottomSheet}
                    >
                        <CancelFormV2
                            cancelDetails={this.props.cancelDetails}
                            orderSummaryPageLabels={this.props.orderSummaryPageLabels}
                            refresh={this.props.refresh}
                            setCancelReason={this.setCancelReason}
                            toggleDropdown={this.toggleDropdown}
                            cancelFilterOption={this.state.cancelFilterOption}
                        />
                    </pui-bottom-sheet>
                </ErrorBoundary>
            </>
        )
    }

    componentDidUpdate(prevProps: CancelV2Props, prevState: CancelV2State) {
        if (this.state.showCloseButton !== prevState.showCloseButton || 
            this.state.isDropdownOpen !== prevState.isDropdownOpen) {
            this.updateCloseButtonVisibility();
        }
    }

    updateCloseButtonVisibility() {
        if (this.state.bottomSheet.current) {
            const shadowRoot = this.state.bottomSheet.current.shadowRoot;
            if (shadowRoot) {
                const closeButton = shadowRoot.querySelector('#pui-bottom-sheet-close-button');
                if (closeButton) {
                    if (this.state.showCloseButton) {
                        closeButton.classList.remove('pui-hidden');
                    } else {
                        closeButton.classList.add('pui-hidden');
                    }
                }
            }
        }
    }
}

export default CancelV2;
